<template>
  <div class="content">
    <div class="title">Not Found</div>
    <div class="msg">The requested URL was not found on this server.</div>
    <el-button plain @click="goback">back</el-button>
  </div>
</template>
<script>
export default {
  methods: {
    goback() {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped lang="scss">
.content{
  margin: 100px;
  .title{
    font-size: 40px;
    font-weight: 700;
  }
  .msg{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
  }
}
</style>
